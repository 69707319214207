import React, { FC } from 'react';
import classNames from 'classnames';

import { ImageProps } from './Image.types';

import './Image.scss';

const Image: FC<ImageProps> = ({ className, ...props }) => (
  <div className={classNames('react-image', className)}>
    <img {...props} alt={props.alt} />
  </div>
);

export default Image;
