import React, { FC } from 'react';
import classNames from 'classnames';

import { PriceProps } from './Price.types';

import './Price.scss';

const Price: FC<PriceProps> = ({ price, color = 'black', size, className }) => (
  <span
    className={classNames('react-price', className, {
      [`react-price--color-${color}`]: color,
      [`react-price--size-${size}`]: size,
    })}
  >
    € {price}
  </span>
);

export default Price;
