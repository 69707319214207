import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
// import '../assets/styles/index.scss';
import Basket from '../components/basket/Basket';

const container = document.getElementById('react-basket');
const root = createRoot(container!); 

root.render(
  
    <Basket />
  
);
