import React, { FC } from 'react';

import { IconName } from '../../icon/Icon.enum';
import IconButton from '../../buttons/iconButton/IconButton';
import IconLabel from '../../labels/iconLabel/IconLabel';
import Image from '../../image/Image';

import { HostelListProps } from './HostelList.types';
import './HostelList.scss';
import { removeHostelFromBasket } from '../../../api/basket/Basket';

const HostelList: FC<HostelListProps> = ({ hostels, groupInfo, labels }) => {

  const singleNightLabel = labels?.nights_one.replace(/[0-9]/, '');
  const multipleNightsLabel = labels?.nights_other.replace('%{count} ', '');

  const singleGuestLabel = labels?.guests_one.replace(/[0-9]/, '');
  const multipleGuestsLabel = labels?.guests_other.replace('%{count} ', '');

  return (
    <div className="react-hostel-list">
      {hostels.map((hostel, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="react-hostel-list__item">
          <Image
            className="react-hostel-list__item-image"
            src={`${hostel.image_path}`}
            alt=""
          />
          <div className="react-hostel-list__item-content">
            <div className="react-hostel-list__item-name">
              <a href={hostel.path} target="_blank" rel="noreferrer">
                {hostel.title}
              </a>
            </div>
            <IconLabel
              className="react-hostel-list__item-start-end-date"
              icon={IconName.CALENDAR}
            >
              {hostel.arrivaldate} - {hostel.enddate}
            </IconLabel>
            <div className="react-hostel-list__item-night-guest">
              <IconLabel
                className="react-hostel-list__item-nights"
                icon={IconName.MOON}
                inline
              >
                {hostel.nights > 1 ? `${hostel.nights} ${multipleNightsLabel}` : `${hostel.nights} ${singleNightLabel}`}
              </IconLabel>
              {groupInfo?.total_guests && (
                <IconLabel
                  className="react-hostel-list__item-guests"
                  icon={IconName.PERSON}
                  inline
                >
                  {groupInfo?.total_guests > 1 ? `${groupInfo?.total_guests} ${multipleGuestsLabel}` : `${groupInfo?.total_guests} ${singleGuestLabel}`}
                </IconLabel>
              )}
            </div>
          </div>
          <div className="react-hostel-list__item-remove-wrapper">
            <IconButton
              className="react-hostel-list__item-delete"
              icon={IconName.TRASH_BIN}
              onClick={() =>
                removeHostelFromBasket(
                  hostel.external_id,
                  hostel.articles[0].item_id,
                )
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default HostelList;
