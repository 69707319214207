import React, { FC, useEffect, useState, useCallback } from "react";

import Button from "../buttons/button/Button";
import Dropdown from "../dropdown/Dropdown";
import Icon from "../icon/Icon";

import { BasketProps } from "./Basket.types";
import "./Basket.scss";
import HostelList from "../lists/hostelList/HostelList";
import Price from "../price/Price";
import Heading from "../typography/heading/Heading";
import { getBasket } from "../../api/basket/Basket";
import Paths from "../../constants/Routes";

const Basket: FC<BasketProps> = () => {
  const sessionStorageBasket = window.sessionStorage.getItem("basket") || "[]";
  const [basketData, setBasketData] = useState<any>(JSON.parse(sessionStorageBasket));

  const listener = useCallback((e) => {
    if (e.data.type === "jquery" || e.data.refetchCompanyData) {
      getBasket(setBasketData);
    }

    if (e.data.basketStorage) {
      setBasketData(JSON.parse(e.data.basketStorage));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [listener]);

  useEffect(() => {
    getBasket();
  }, []);
  
  return (
    <Dropdown className="react-dropdown--basket" dropdownHasItems={basketData?.hostels?.length > 0} icon="suitcase" counter={basketData?.hostels?.length > 0 ? basketData?.hostels.length : ""} dropDownSubWidth={350}>
      <div className="react-basket__container">
        <div className="react-basket__title">
          {basketData?.labels?.title}
        </div>
        <div className="react-basket__main">
          {basketData?.hostels?.length > 0 ? (
            <>
              <HostelList hostels={basketData?.hostels} groupInfo={basketData.group} labels={basketData.labels} />
              <div className="react-basket__total-price">
                <strong>{basketData?.labels?.to_pay}</strong>
                <Price price={basketData?.total_price} color="green" size="large" />
              </div>
              <div className="react-basket__tourist-tax">
                {basketData?.tourist_tax.title}
                <Price price={basketData?.tourist_tax.price} color="green" />
              </div>
              <span className="react-basket__subtext">{basketData?.labels?.tourist_tax_pay_on_arrival}</span>
            </>
          ) : (
            <div className="react-basket__paragraph">{basketData?.labels?.no_hostels}</div>
          )}
        </div>

        {basketData?.hostels?.length > 0 ? (
          <>
            {!basketData.group.group_booking && (
              <Button
              tag="a"
              flat
              fullWidth
                // @ts-ignore
                href={Paths[document.documentElement.lang].HOSTEL_OVERVIEW}
                className="react-basket__button-continue react-btn--red"
              >
                {basketData?.labels?.add_another_hostel}
              </Button>
            )}
            <Button
              tag="a"
              flat
              fullWidth
              // @ts-ignore
              href={basketData.group.group_ok ? Paths[document.documentElement.lang].BOOKING_EXTRA_OPTIONS : basketData.last_hostel_price_page}
              className="react-basket__button-continue"
            >
              {basketData.group.group_ok ? basketData?.labels?.book_now : basketData.labels.book_now_group_nok}
              <Icon name="arrow" size="medium" className="react-basket__arrow" />
            </Button>
          </>
        ) : (
          <Button
            tag='a'
            flat
            fullWidth
            // @ts-ignore
            href={Paths[document.documentElement.lang].HOSTEL_OVERVIEW}
            className="react-basket__button-continue"
          >
            {basketData?.labels?.destinations}
            <Icon name="arrow" className="react-basket__arrow" />
          </Button>
        )}
      </div>
    </Dropdown>
  );
};

export default Basket;
