import React, { FC } from 'react';
import classNames from 'classnames';

import { IconLabelProps } from './IconLabel.types';
import Icon from '../../icon/Icon';

import './IconLabel.scss';

const IconLabel: FC<IconLabelProps> = ({
  children,
  icon,
  labelSize = 14,
  inline,
  ...props
}) => (
  <label
    {...props}
    className={classNames('react-icon-label', props.className, {
      'react-icon-label--inline': inline,
      [`react-icon-label--size-${labelSize}`]: labelSize,
    })}
  >
    <Icon className="react-icon-label__icon" name={icon} />
    <div className={classNames('react-icon-label__label')}>{children}</div>
  </label>
);

export default IconLabel;
