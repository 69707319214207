export enum IconName {
  ARROW = "arrow",
  ARROW_DOWN = "arrowDown",
  CALENDAR = "calendar",
  MOON = "moon",
  PERSON = "person",
  SUITCASE = "suitcase",
  TRASH_BIN = "trash-bin",
  CLOSE = "close",
  PERSON_FACE = "personFace",
  WOMAN_OPEN = "womanOpen",
  WOMAN_CLOSED = "womanClosed",
  CHILD_OPEN = "childOpen",
  CHILD_CLOSED = "childClosed",
  BABY_OPEN = "babyOpen",
  BABY_CLOSED = "babyClosed",
}

export enum IconSize {
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  XLARGE = "xlarge",
  XXLARGE = "xxlarge",
}
