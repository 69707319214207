import { reverse } from 'named-urls';
import fetchData from '../../utils/fetchData';
import Paths from '../../constants/Routes';

export const getBasket = async (setBasketData?: any) => {
  
  const promise = await fetchData({
    url: Paths.API.BASKET_GET,
    options: {
      method: 'GET',
    },
  });

  return promise()
    .then((response) => response.json())
    .then((myJson) => {
      if (setBasketData) {
        setBasketData(myJson);
      }
      window.postMessage({ basketStorage: JSON.stringify(myJson) });
      window.sessionStorage.setItem('basket', JSON.stringify(myJson));
    })
    .catch(() => {});
};

export const removeHostelFromBasket = (
  hostelExternalId: number,
  articleItemId: string,
) => {
  const response = fetchData({
    url: reverse(Paths.API.BASKET_DELETE, {
      hostelExternalId,
      articleItemId,
    }),
    options: {
      method: 'DELETE',
    },
  });

  return response().then((response) => {
    if (response.status === 200) {
      location.reload();
    }
  });
};

export default getBasket;
