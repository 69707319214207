import { FetchDataAction } from './fetchData.types';

const fetchData =
  ({ url, options, asJson = true, signal }: FetchDataAction) =>
  async () => {
    let jsonHeaders = {};
    if (asJson) {
      jsonHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }

    const response = await fetch(
      `/${document.documentElement.lang}/api${url}`,
      {
        signal,
        method: 'GET',
        headers: {
          ...jsonHeaders,
        },
        ...options,
      },
    );

    switch (response.status) {
      case 503:
      case 427:
        break;
      case 403:
        break;
      case 201:
      case 200:
        return response;
      default:
        break;
    }

    return response;
  };

export default fetchData;
