import classNames from 'classnames';
import React, { FC, MouseEvent, TouchEvent, useState } from 'react';
import Icon from '../icon/Icon';
import './Dropdown.scss';
import { DropdownProps } from './Dropdown.types';



const Dropdown: FC<DropdownProps> = ({
  children,
  className,
  active = false,
  icon,
  counter,
  dropDownSubWidth = 350,
  dropdownHasItems,
}) => {
  const [isActive, setIsActive] = useState(active);

  const setNavigationActive = () => {
    if (document?.querySelector('.react-dropdown__toggle')?.classList.contains('is-active')) {
      document.querySelector('.bookingbar-container')?.classList.remove('is-active');
      document.querySelector('.react-dropdown__toggle')?.classList.remove('is-active');
      document.querySelector('.navigation-bar')?.classList.remove('is-active');
      document.querySelector('body')?.classList.remove('no-scroll');

      if (document.querySelectorAll('.subnav__container--sticky')?.length > 0) {
        document.querySelector<HTMLElement>('.subnav__container--sticky').style.zIndex = "190"
      }
    } else {
      document.querySelector('.react-dropdown__toggle')?.classList.add('is-active');
      document.querySelector('body')?.classList.add('no-scroll');
      document.querySelector('.navigation-bar')?.classList.add('is-active');

      if (document.querySelectorAll('.subnav__container--sticky')?.length > 0) {
        document.querySelector<HTMLElement>('.subnav__container--sticky').style.zIndex = "100"
      }
    }
  };

  const handleHover = (e:MouseEvent | TouchEvent) => {
    const basketContainer = document.querySelector<HTMLElement>(".react-basket__container")
        
    if (!basketContainer?.contains(e.target as HTMLElement)) {
      setIsActive((prevState) => !prevState);
      handleDropdown()
    }
  };
  
  const handleDropdown = () => {
    if (window.matchMedia('(max-device-width: 960px)').matches) {
      setNavigationActive();
    } else {
      if (isActive && document.querySelectorAll('.subnav__container--sticky')?.length > 0) {
        document.querySelector<HTMLElement>('.subnav__container--sticky').style.zIndex = "190"
      } else if (document.querySelectorAll('.subnav__container--sticky')?.length > 0) {
        document.querySelector<HTMLElement>('.subnav__container--sticky').style.zIndex = "100"
      }
    }
  }

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      className={classNames('react-dropdown', className, {
        'react-dropdown--active': isActive,
      })}
      >
      <div
        onTouchStart={handleHover}
        onTouchEnd={(e) => e.preventDefault()}
        className={classNames('react-dropdown__toggle', {
          [`react-dropdown__toggle--has-items`]: dropdownHasItems,
        })}
      >
        <Icon name={icon} size="medium" />
        {counter && (
          <div className="react-dropdown__toggle-counter">{counter}</div>
        )}
      </div>
      <div
        className={classNames('react-dropdown__sub', className, {
          [`react-dropdown__sub--width-${dropDownSubWidth}`]: dropDownSubWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
